.activities {
  grid-area: activities;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1.5rem 1rem 0.5rem;
  border-bottom: 1px solid #ebebeb;
  height: 100%;

  .activity {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .label {
      min-width: 6.125rem;
    }

    .bar {
      height: 1.5rem;
      border-top-right-radius: 0.75rem;
      border-bottom-right-radius: 0.75rem;
    }
  }

  .spacer {
    margin-top: auto;
  }
}
