.page-header {
  background-color: #ccccccff;
  height: 10%;
  .ant-page-header-heading {
    .ant-page-header-heading-extra {
      display: flex;
      margin-top: -15px;
      .ant-btn {
        display: flex;
        flex-direction: row;
        font-size: 1em;
        padding: 8px 16px;
        height: 100%;
        color: #4d4d4dff;
        border: none;
        :focus,
        :hover {
          color: #4d4d4dff;
        }

        .page-header-avatar {
          background-color: #4d4d4dff;
          margin: 8px 8px 0px 0px;
        }

        .page-header-details {
          display: flex;
          flex-direction: column;
        }

        .page-header-user {
          margin-top: 8px;
        }

        .page-header-dp-icon {
          margin-left: 8px;
          align-self: center;
        }

        .page-header-sub-label {
          position: absolute;
          top: 36px;
          font-size: .6em;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
}

@media only screen and (min-width: 992px) {
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1600px) {
  .page-header {
    .ant-page-header-heading {
      .ant-page-header-heading-extra {
        .ant-btn {
          font-size: 1.2em;
          .page-header-sub-label {
            top: 40px;
            font-size: .6em;
          }
        }
      }
    }
  }
}
