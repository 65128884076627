$white: #ffffff;
$dark: #000000ff;

$backgroundColorLight: #ececec;
$backgroundColorNormal: #ccccccff;
$backgroundColorDark: #80808045;

$fontColorLight: #ecececff;
$fontColor: #666666ff;
$fontHeaderColor: #4d4d4dff;
$fontTitleColor: #1e5c8bff;

$lightRed: #ffccccff;
$red: #ff0000ff;

$available: #00ff00ff;
$availableBG: #ccffccff;
$pendingApptTime: #fa8c16ff;
$pendingApptTimeBG: #ffe0beff;
$pendingParts: #d46b08ff;
$pendingPartsBG: #f3cca8ff;
$offline: #8c8c8cff;
$offlineBG: #dbdbdbff;
$enRoute: #0000ffff;
$enRouteBG: #8989ffff;
$runningLate: #ff4d4fff;
$runningLateBG: #ffa0a1ff;
$onSite: #ffff00ff;
$onSiteBG: #ffffccff;
$issue: #610b00ff;
$issueBG: #9b534aff;

$multipleTechnician: #ccccffff;
$singleTechnician: #ccffccff;

$backgroundHighlight: #477187ff;
$backgroundDarkblue: #1e5c8bff;


.border-color-right {
   border-right: 1px solid $backgroundColorDark;
}

.border-color-left {
   border-left: 1px solid $backgroundColorDark;
}

.border-color-top {
   border-top: 1px solid $backgroundColorDark;
}

.border-color-bottom {
   border-bottom: 1px solid $backgroundColorDark;
}

.background-color-white {
   background-color: $white;
}

.font-color-white {
   color: #e9e9e9ff;
}

.font-color-light {
   color: $fontColorLight;
}

.font-color {
   color: $fontColor;
}

.font-color-2 {
   color: $fontHeaderColor;
}

.font-title-color {
   color: $fontTitleColor;
}

.background-color-dark-blue {
   background-color: $backgroundDarkblue;
}

.background-color-highlight {
   background-color: $backgroundHighlight;
}
