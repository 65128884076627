$color_dark_blue: #1e5c8bff;

// Custom css here
html,
body {
  overflow: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", Sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw !important;
  height: 100vh !important;
}

#root {
  height: 100%;
  width: 100%;
}

html {
  --antd-wave-shadow-color: #1e5c8bff;
}

.ant-layout-sider-zero-width-trigger {
  top: 10px;
}

.ant-input,
.ant-picker,
.ant-select,
.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-input-affix-wrapper {
  border-radius: 4px;
  border-width: 2px;
}

.ant-input-affix-wrapper:hover,
.ant-picker:hover,
.ant-select:hover,
.ant-input-affix-wrapper:active {
  border-color: $color_dark_blue;
  ::selection {
    background: $color_dark_blue;
  }
}
.ant-upload.ant-upload-drag {
  border: 2px solid#d9d9d9;
}

.ant-upload.ant-upload-drag:hover {
  border: 2px solid#878686;
}

.ant-input:hover,
.ant-picker:hover,
.ant-select:hover,
.ant-select:not(.ant-select-customize-input) .ant-select-selector:hover,
.ant-input:active {
  border-color: $color_dark_blue;
  ::selection {
    background: $color_dark_blue;
  }
}

.ant-timepicker {
  .ant-picker {
    visibility: hidden;
  }
}
.vendor-status .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #00d400;
  width: 12vh;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-weight: bold;
  font-size: 1.7vh;
  color: white;
}

.ant-input::placeholder {
  color: gray;
  font-weight: 500;
}

.ant-input:focus {
  border-color: $color_dark_blue;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
  box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
}

.required-input-msg {
  border: 2px solid #b22222;
}

.ant-collapse-header-text {
  width: 100% !important;
}

.upload-container {
  border: 2px solid lightgrey;
  &:hover {
    border: 2px solid $color_dark_blue;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
    box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
  }
}

.chat-modal {
  .ant-modal-header {
    background-color: #7aaed6;
    height: 75px;
    .ant-modal-title {
      font-weight: 400;
      font-size: 20px;
    }
  }

  .ant-modal-body {
    flex-direction: row;
    padding: 32px 32px;
  }

  #chat-content-scroller {
    overflow:auto;
  }

  .chat-content {
    height: 300px;
    border: 1px solid #e0e0e0;
    padding: 32px;
  }
  .chat-tools {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
  }

  .chat-textInput {
    margin-top: 1px;
    width: 100%;
  }

  .chat-btn {
    background-color: #2e71a4;
    height: 57px;
    width: 75px;
    color: #d2f1ff;
    font-size: 16px;
  }

  .chat-btn:disabled {
    color: #d2f1ff;
    background-color: #babbbb;
  }

  .bubble-container-left {
    display: flex;
    justify-content: flex-start;
  }

  .bubble-container-right {
    display: flex;
    justify-content: flex-end;
  }

  .bubble {
    display: flex;
    padding: 8px;
    padding-left: 12px;
    padding-right: 12px;
    align-self: flex-end;
    border-radius: 10px;
    margin-top: 8px;
  }

  .upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
  }
  
  .btn {
    background-color: #2e71a4;
    color: #d2f1ff;
    padding: 8px 20px;
    border-radius: 8px;
    font-size: 20px;
  }
  
  .upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
}
