.filter-builder {

  background: white;
  border-radius: 16px;
  margin: 1vh;
  height: calc(100vh - 20vh);

  .outer {

    align-items: center;
    border: 1px solid #1e5c8bff;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    height: 16px;
    justify-content: center;
    width: 16px;

    .inner {

      background: #1e5c8bff;
      border-radius: 6px;
      height: 12px;
      justify-content: center;
      width: 12px;
    }
  }

  .filter-list-container {

    padding: 2vh;
    display: inline-block;
    border-right: 1px solid #eee;
    height: 100%;
    vertical-align: top;
    width: 40vh;
    
    .header {

      align-items: center;
      display: grid;
      grid-template-columns: auto 14vh;
      padding: 1vh 0;

      h3 {
        font-weight: bold;
        margin: 0;
      }

      div {
        cursor: pointer;
        color: #1e5c8bff;
        text-align: right;
      }
    }

    .preset-header {

      color: #1e5c8bff;
      font-size: 8pt;
      font-weight: bold;
      text-align: right;
      padding: 2vh 1vh 0vh 0vh;
    }

    .filter-list {

      height: calc(100% - 104px);
      overflow: auto;

      .filter-item {

        align-items: center;
        color: gray;
        cursor: pointer;
        display: grid;
        grid-template-columns: auto 2vh;
        padding: 1.5vh 2vh 1.5vh 2vh;
      }
    }
  }

  .filter-detail {

    background: white;
    display: inline-block;
    height: 100%;
    padding: 2vh;
    vertical-align: top;
    width: calc(100% - 40vh);

    .header {

      align-items: center;
      display: grid;
      grid-template-columns: auto 30vh 16vh;
      column-gap: 1vh;
      margin-bottom: 1vh;
      margin-right: 1vh;
      padding: 1vh 0;

      h3 {
        font-weight: bold;
        color: #1e5c8bff;
        margin: 0;
      }

      .input-header {
        border: 1px solid white;
        font-size: 11pt;
        font-weight: bold;
        color: #1e5c8bff;
        
        &:hover {
          border: 1px solid lightgray;
        }
      }
    }

    .filter-detail-container {

      height: calc(100% - 6vh);
      overflow: auto;

      .filter-group {

        .group-operator-container {

          border-top: 2px solid #eee;
          margin-top: 24px;

          .group-operator-buttons {
            margin-top: -24px;
            padding: 1vh;
            text-align: center;
            width: 100%;
          }
        }
        
        .filter-group-container {

          background: #eeeeee;
          display: grid;
          grid-template-columns: 48px auto;

          .filter-icon {
            border-bottom: 2px solid white;
            text-align: center;
            padding-top: 4vh;
          }

          .rules-container {
            padding-right: 2vh;
          }
        }
      }

      .filter-tag-container {

        background: #eeeeee;
        margin-top: 2vh;
        padding: 1vh;

        .filter-tag-items {
          
          background: white;
          padding: 2vh;

          .site-tag-plus {
            background: #fff;
            border-style: dashed;
          }

          .edit-tag {
            user-select: none;
          }

          .tag-input {
            width: 78px;
            margin-right: 8px;
            vertical-align: top;
          }
        }
        
      }

      .preset {
        align-items: center;
        display: flex;
        justify-content: center;
        padding-top: 4vh;
      }

      .controls {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 2vh;
      }

      .filter-sub-group {
          
        border-bottom: 2px solid white;
        padding: 3vh 0vh;
        position: relative;

        .sub-group-operator-buttons {
          position: absolute;
          text-align: center;
          top: -2vh;
          width: 100%;
        }

        .header {
          align-items: center;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
        }

        .filter-rules {

          margin-top: -6vh;
          
          .filter-rule {

            align-items: center;
            display: grid;
            grid-template-columns: auto 5vh;
            padding: 1vh 0px;

            div:nth-child(2) {
              text-align: right;
            }
          }
        }
      }
    }
  }
}
