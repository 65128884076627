.card {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: white;
  font-size: 1rem;

  &.small {
    gap: 0.5rem; // 8px
  }

  &.medium {
    gap: 1rem; // 16px
  }

  &.large {
    gap: 1.5rem; // 24px
  }

  .title {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.5rem;
  }
}
