@media only screen and (min-width: 1600px) {
  .scheduler-grid-container {
    .map-tab-content {
      display: flex;
      flex-direction: row;
      height: calc(100vh - 240px);

      .map-tab-content-left {
        height: inherit;
        width: 300px;
        min-width: 300px;
      }

      .map-tab-content-right {
        height: inherit;
        width: 100%;
        display: flex;
        flex-direction: column;

        .map-container {
          flex-grow: 2;
        }

        .sr-container {
          flex-grow: 150px;
        }
      }
    }
  }
}
