.weekly-grid-cell-collapse,
.daily-grid-cell-collapse {
   .ant-collapse-item {
      border-radius: unset !important;
      height: 100px !important;
      width: 100%;

      .ant-collapse-header {
         padding: 8px !important;
         height: inherit !important;
         white-space: nowrap !important;
         overflow: hidden !important;
         text-overflow: ellipsis !important;
      }

      .ant-collapse-content {
         border-right: 1px solid#80808045;
         border-left: 1px solid #80808045;
         border-bottom: 1px solid #80808045;

         .ant-collapse-content-box {
            padding: 2px !important;
            font-size: 0.8em !important;
            color: #4d4d4dff !important;
         }
      }
   }
}

.sr-header {
   &:hover {
      color: #1e5c8bff;
   }
}