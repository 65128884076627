.scheduler-sidebar {
  overflow-y: scroll;
  height: 100%;
  * {
    color: #6a6868;
  }
  .ss-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 2;
  }
  .ss-card-pending {
    border: 1px solid #fcc;
  }
  .ss-card-bidout {
    border: 1px solid #eeccf6;
  }
  .ss-pending-header {
    background-color: #fcc;
    cursor: move;
  }
  .ss-bidout-header {
    background-color: #eeccf6;
  }
  .ss-body {
    position: relative
  }
  .ant-collapse-header {
    padding: 0 !important;
  }
  .ant-collapse-item {
    border: 0;
  }
  .ant-collapse-content-box {
    padding: 8px 14px !important;
  }
  .ss-bidout {
    position: absolute;
    right: 0;
    bottom: 0;
    margin-bottom: -10px;
    margin-right: -10px;
    height: 22px;
    width: 15px;
    &:hover {
      font-weight: 900;
      background-color: #eee;
      border-radius: 3px;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
    }
  }
  .ss-bidout-ul {
    list-style-type: none;
    list-style: none;
  }
}