.table {
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: auto;

  &.auto {
    table-layout: auto;
  }

  &.fixed {
    table-layout: fixed;
  }

  tr {
    border-bottom: 1px #ebebeb solid;

    th {
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    th,
    td {
      padding: 0.5rem 0;
      line-height: normal;
      font-size: clamp(0.875rem, 2vw, 1rem);
    }
  }

  .name,
  .lastUpdate,
  .ranking {
    text-align: start;
  }

  tbody {
    td {
      text-align: center;
    }

    tr:last-of-type {
      border-bottom: 0;
    }

    .status:not(td) {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .serviceType:not(td) {
      width: 10rem;
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }

    td.name,
    td.lastUpdate,
    td.propertyId {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    td.daysOverdue {
      color: #f9665f;
      font-weight: 600;
    }

    .rating {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.25rem;
    }
  }

  tfoot td {
    padding: 0;
  }
}
