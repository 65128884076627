.claim-history {
  background-color: #fff;
  z-index: 5;
  height: 100%;
  width: 300px;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 10px;
  border: 1px solid #eee;
  .ch-close-btn {
    position: absolute;
    right: 5px;
    top: 10px;
    width: 16px;
    height: 16px;
    opacity: 0.8;
  }
  .ch-close-btn:hover {
    opacity: 1;
  }
  .ch-close-btn:before, .ch-close-btn:after {
    position: absolute;
    content: ' ';
    height: 16px;
    width: 2px;
    background-color: rgb(131, 131, 131);
  }
  .ch-close-btn:before {
    transform: rotate(45deg);
  }
  .ch-close-btn:after {
    transform: rotate(-45deg);
  }
  .ch-search{
    position: relative;
    .anticon-search {
      position: absolute;
      margin: 5px;
    }

    input[type=text]{
      border: 1px solid rgb(145, 145, 145);
      padding: 0 22px;
      outline: 0;
    }
  }
  .ch-list{
    border: 1px solid rgb(206, 206, 206);
    display: flex;
  }
}