.service-type-container {
   background: white;
   border-radius: 16px;
   margin: 1vh;
   height: calc(100vh - 20vh);

   .service-type-list-container {
      display: inline-block;
      height: 100%;
      vertical-align: top;
      width: 100%;
      .header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 20px;

         h3 {
            font-weight: bold;
            margin: 0;
         }
      }
   }

   .service-type-list {
      height: calc(100% - 100px);
      overflow-x: hidden;
      font-size: 16px;
      margin-left: 24px;
      line-height: 18px;
   }
}

.modalStyle .ant-modal-header {
   background-color: #7badd6;
}
