.add-vendor-container {
  padding: 10vh;
  .add-vendor-button-right {
    float: right;
    display: flex;
    .button-import {
      margin-right: 2vh;
      color: white;
      background-color: #550000;
    }
    .button-add {
      color: white;
      background-color: #008000;
    }
  }
  .add-vendor-company-info {
    margin-top: 10vh;
    .add-vendor-company-info-details {
      display: flex;
      margin-top: 3vh;
      .add-vendor-company-sub-details {
        margin-right: 12vh;
        width: 32vh;
      }
    }
  }
}
