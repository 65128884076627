$background_color: #1e5c8bff;
$background_color_highlight: #477187ff;
$text_color: #ecececff;

.reset-password-link {
   font-style: italic;
   font-size: 0.8em;
   color: #3a78b3ff;
   :hover,
   :focus,
   :active {
      color: $background_color_highlight;
   }
}
