.container {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;

  .bar-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    .bar-container {
      height: 11rem;
      display: grid;
      place-items: end;

      .bar {
        width: 1.5rem;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
      }
    }

    .label {
      font-size: clamp(0.75rem, 1.2vw, 1rem);
      font-weight: 600;
    }
    .value {
      margin-top: 0.5rem;
      font-size: clamp(1.25rem, 0.5vw, 1.75rem);
      line-height: 2rem;
      font-weight: 700;
    }
  }
}
