.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  overflow-y: scroll;
  height: 90vh;
  background: #eef4f9;
}

.grid-primary {
  display: grid;
  gap: 1rem;
  grid-template-columns: 100%;
  grid-template-areas:
    "technicians"
    "members"
    "jobs"
    "outstanding"
    "revenue"
    "payments"
    "invoices"
    "activities";

  .technicians {
    grid-area: technicians;
  }

  .members {
    grid-area: members;
  }

  .jobs {
    grid-area: jobs;
  }

  .outstanding {
    grid-area: outstanding;
  }

  .payments {
    grid-area: payments;
  }

  .revenue {
    grid-area: revenue;
  }

  .invoices {
    grid-area: invoices;
  }

  .activities {
    grid-area: activities;
  }
}

.grid-secondary {
  display: grid;
  grid-template-columns: 100%;
  grid-template-areas:
    "highest"
    "lowest"
    "requests"
    "orders"
    "map";
  gap: 1rem;

  .highest {
    grid-area: highest;
  }

  .lowest {
    grid-area: lowest;
  }

  .requests {
    grid-area: requests;
  }

  .orders {
    grid-area: orders;
  }

  .map {
    grid-area: map;
    height: auto;
  }
}

.grid-tertiary {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

@media (width >= 1024px) {
  .grid-primary,
  .grid-secondary {
    grid-template-columns: repeat(2, 1fr);
  }

  .grid-primary {
    grid-template-areas:
      "technicians technicians"
      "members members"
      "outstanding outstanding"
      "jobs payments"
      "revenue revenue"
      "invoices activities";
  }

  .grid-secondary {
    grid-template-areas:
      "highest requests"
      "lowest orders"
      "map map";
  }
}

@media (width >= 1280px) {
  .container {
    padding: 2rem;
  }

  .container,
  .grid-primary,
  .grid-secondary,
  .grid-tertiary {
    gap: 2rem;
  }

  .grid-primary {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
      "technicians technicians payments"
      "members members jobs"
      "outstanding outstanding invoices"
      "revenue revenue activities";
  }

  .grid-tertiary {
    grid-template-columns: 356px 608px;
  }
}

@media (width >= 1765px) {
  .container {
    padding: 2rem 5rem;
  }

  .grid-primary {
    grid-template-columns: 6fr 2fr 4fr 5fr 2fr 5fr;
    grid-template-areas:
      "technicians technicians technicians members members members"
      "jobs jobs outstanding outstanding outstanding payments"
      "revenue revenue revenue invoices activities activities";
  }

  .grid-secondary {
    grid-template-columns: 7fr 6fr 11fr;
    grid-template-areas:
      "highest requests map"
      "lowest orders map";
  }
}
