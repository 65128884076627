.page-layout {
  height: 100vh;

  .page-layout-l {
    background-color: #1e5c8bff;
  }

  .page-layout-r {
    background-color: #ccccccff;
  }
}

