.grid-form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  @media (width >= 940px) {
    grid-template-columns: repeat(2, 1fr);
  }

  label {
    line-height: 1.25rem;
    color: #293944;
  }

  .select {
    position: relative;

    input {
      width: 100%;
      margin-top: 0.5rem;
      padding: 0.75rem;
      outline: none;
      border-radius: 6px;
      border: 1px solid #ececec;
    }

    .dropdown {
      position: absolute;
      top: 50%;
      right: 0.75rem;
      color: #0c71c3;
    }

    .calendar {
      position: absolute;
      top: 1.5rem;
      right: 0.75rem;
      color: #0c71c3;
      font-size: 0.875rem;
    }
  }

  .radio-row {
    display: flex;
    align-items: center;
    gap: 1rem;

    .radio-empty {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      border: 2px solid #ebebeb;
    }

    .radio-label {
      font-size: 0.875rem;
    }
  }
}

.btn-generate {
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #0c71c3;
  color: white;
  font-weight: 700;
  border: 0;
}
