.invoices {
  grid-area: invoices;
  min-height: 272px;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem 0;
  border-bottom: 1px solid #ebebeb;

  .total-invoiced {
    font-size: 1.75rem;
    line-height: 2rem;
    font-weight: 700;
    color: #145cb0;
  }

  .outstanding {
    font-size: 1.5rem;
    line-height: 1.75rem;
    font-weight: 700;
    color: #f9665f;
  }

  .label {
    font-weight: 600;
    line-height: 1.25rem;
  }

  .bar {
    position: relative;
    height: 1.5rem;
    border-radius: 0.75rem;
    background-color: #a5a5a5;
    overflow: clip;

    &-invoices,
    &-outstanding {
      position: absolute;
      height: 24px;
    }

    &-invoices {
      width: 85%;
      background-color: #145cb0;
      border-radius: 12px;
      z-index: 2;
    }

    &-outstanding {
      width: 20%;
      background-color: #f9665f;
      right: 0;
      z-index: 1;
    }
  }
}
