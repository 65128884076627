.revenue {
  grid-area: revenue;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .jobs {
    display: flex;
    align-self: flex-start;
    gap: 0.5rem;

    & > div:nth-child(1) {
      font-size: 1.75rem;
      font-weight: 700;
      line-height: 2rem;
    }

    & > div:nth-child(2) {
      color: #293944;
      line-height: 1.25rem;
    }
  }

  .averages {
    display: flex;
    justify-content: end;
    gap: 0.5rem;
    flex-wrap: wrap;

    .mtd {
      & > div:nth-child(1) {
        width: 8rem;
        font-weight: 700;
        color: #145cb0;
      }

      & > div:nth-child(2) {
        line-height: 1.25rem;
        font-weight: 600;
        color: #293944;
      }
    }

    .ytd {
      & > div:nth-child(1) {
        width: 8rem;
        font-weight: 700;
        color: #f9973d;
      }

      & > div:nth-child(2) {
        line-height: 1.25rem;
        font-weight: 600;
        color: #293944;
      }
    }
  }
}
