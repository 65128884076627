@import "../default";

.login-form-title {
  display: flex;
  padding: 16px 32px;
  font-size: 1.6em;
  color: $fontColor;
}


// .login-form-title {
//   display: flex;
//   flex: 1;
//   padding: 16px;
//   font-size: 1.2em;
//   color: #666666ff;
// }

// .login-form {
//   .ant-form-item {
//     margin-bottom: 16px;
//     .ant-input {
//       font-size: 1em;
//     }
//   }
// }

// @media only screen and (max-width: 768px) {
//   .login-form-title {
//     font-size: 1.6em;
//   }

//   .login-form {
//     .ant-form-item {
//       .ant-input {
//         font-size: 1.2em;
//       }
//     }
//   }
// }

// @media only screen and (min-width: 1600px) {
//   .login-form-title {
//     font-size: 1.6em;
//   }

//   .login-form {
//     .ant-form-item {
//       .ant-input {
//         font-size: 1.4em;
//       }
//     }
//   }
// }

