@import "../../assets/stylesheets/default";

.scheduler-page {
   height: 90%;

   .ant-tabs-nav {
      margin-bottom: 0 !important;
   }
   .ant-tabs-content-holder {
      padding: 16px;
      background-color: $backgroundColorLight;
   }
}

.ant-tabs-nav-wrap {
   .ant-tabs-nav-list {
      margin-left: 10px;
      .ant-tabs-tab {
         display: flex;
         justify-content: center;
         margin-left: 4px !important;
         min-width: 100px !important;
         background-color: $backgroundDarkblue !important;
         border: transparent !important;
         margin-bottom: 0px;
      }
      .ant-tabs-tab-active {
         background-color: $backgroundColorLight !important;

         .ant-tabs-tab-btn {
            color: $dark;
            font-weight: 700;
         }
      }
   }
}

.ant-tabs-tab {
   border-top-right-radius: 8px !important;
   border-top-left-radius: 8px !important;
   .ant-tabs-tab-btn {
      color: $white;
   }
}

.ant-tabs-content {
   height: 100%;


   .ant-tabs-tabpane {
      height: 100%;
   }
}
