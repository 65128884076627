$border: #80808045;
$fcolorLight: #666666ff;
.map-tab-content {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 200px);
  .map-tab-content-left {
    height: inherit;
    width: 240px;
    min-width: 240px;

    .map-technician-list-container {
      display: flex;
      flex-direction: row;
      overflow-y: scroll;
      overflow-x: hidden;
      height: inherit;
      .map-technician-list {
        width: 70%;
        height: fit-content;
        border-right: 1px solid $border;
        border-left: 1px solid $border;
        .map-technician-search {
          display: flex;
          width: 100%;
          height: 40px;
          justify-content: center;
          align-items: center;

          .map-technician-search-wrapper {
            height: 25px;
            width: 70%;
            padding: 0px 4px;
            border-radius: 8px;
            border: 1px solid $border;
            input:focus {
              outline: 0;
            }
            input {
              border: unset;
              margin-left: 8px;
              height: inherit;
              width: inherit;
              background-color: transparent;
            }
          }
        }

        .map-technician-list-header {
          display: flex;
          padding: 8px 0px;
          justify-content: center;
          border-bottom: 1px solid $border;
        }

        .map-technician {
          display: flex;
          flex-direction: row;
          padding: 8px;
          height: 75px;
          cursor: pointer;
          border-bottom: 1px solid $border;
          color: $fcolorLight;

          .map-technician-avatar {
            margin-right: 8px;
          }

          .map-technician-name {
            font-size: 1em;
            font-weight: 700;
          }

          .map-technician-skill,
          .map-technician-id {
            font-size: 0.8em;
          }
        }
      }

      .map-technician-sr {
        width: 30%;
        .map-technician-sr-header {
          display: flex;
          margin-top: 40px;
          padding: 8px 0px;
          justify-content: center;
          border-bottom: 1px solid $border;
        }
        .map-technician-sr-count {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px;
          height: 75px;
          border-bottom: 1px solid $border;
        }
      }
    }
  }

  .map-tab-content-right {
    height: inherit;
    width: calc(100% - 240px);
    display: flex;
    flex-direction: column;

    .map-container {
      flex-grow: 2;
      .google-map-container {
        height: "100%";
        width: "100%";
        @keyframes heartbeat {
          0% {
            transform: scale(0.75);
          }
          20% {
            transform: scale(1.25);
          }
          40% {
            transform: scale(0.75);
          }
          60% {
            transform: scale(1.25);
          }
          80% {
            transform: scale(0.75);
          }
          100% {
            transform: scale(1.25);
          }
        }
        .service-request-home-icon {
          animation: heartbeat 1s infinite;
        }
      }
    }

    .sr-container {
      flex-grow: 150px;
      .map-service-request-list-container {
        .map-service-request-list-table
          .ant-table
          .ant-table-tbody
          > tr:hover
          > td {
          background: unset;
        }
        .map-service-request-list-table-activeRow {
          background-color: #1e5c8bff;
          color: white;
        }
      }
    }
  }
}
.current-service-popover {
  .ant-popover-content {
    .ant-popover-arrow {
      top: 8px;
      left: -10px;
      border-top-color: transparent;
      border-right-color: transparent;
      border-bottom-color: #ccccff;
      border-left-color: #ccccff;
      width: 12px;
      height: 12px;
      border-width: 20px;
    }

    .ant-popover-inner {
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
      border-bottom-left-radius: 12px;
      width: 232px;
      .ant-popover-title {
        border-top-right-radius: 12px;
        background-color: #ccccff;
        .current-service-request-header {
          color: #4d4d4dff;
          .current-service-request-title {
            font-size: 1.2em;
            font-weight: 700;
          }

          .current-service-request-member {
            font-size: 1em;
            font-weight: 400;
          }
        }
      }

      .ant-popover-inner-content {
        .current-service-request-content {
          font-size: 1em;
          color: #4d4d4dff;
          .anticon {
            color: #4d4d4dff;
          }
        }
      }
    }
  }
}
