.map {
  grid-area: map;
  height: auto;
}

.job-heat-map {
  position: relative;
  overflow: clip;

  .image-map {
    width: 100%;
    height: 32rem;
    object-fit: cover;
  }

  & > *:not(.image-map) {
    position: absolute;
  }

  &-1 {
    bottom: 0;
    right: 215px;
  }

  &-2 {
    bottom: 112px;
    right: 0;
  }

  &-3 {
    top: 80px;
    left: 80px;
  }

  &-4 {
    top: 96px;
    right: 8px;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0.5rem;

  .col {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .label {
      line-height: 1.25rem;
      font-weight: 600;
    }

    .numbers {
      display: flex;
      gap: 0.5rem;

      .number {
        width: 3rem;
        height: 2.5rem;
        line-height: 1.25rem;
        color: white;
        display: grid;
        place-items: center;
      }
    }
  }
}
