$white: #ffffffff;
$black: #000000ff;
$border: #80808045;
$cellColor: #ccccccff;
$iconColor: hsl(0, 0%, 40%);
$fcolorLight: #666666ff;

.notification-main-wrapper {
  height: calc(100vh - 200px);
  box-shadow: rgba(0, 0, 0, 0.8) 0 0 5px;
  background-color: white;
  padding: 16px;
  width: 500px;
  border-radius: 16px;
  .notification-scroller {
    height: calc(100vh - 230px);
    overflow-y: scroll;
    overflow-x: hidden;
    visibility: hidden;
    &:hover {
      visibility: visible;
    }
    &:focus {
      visibility: visible;
    }
    .notification-wrapper {
      visibility: visible;
      .ant-list {
        border: unset;
      }
      .nofication-item-wrapper {
        display: flex;
        flex-direction: row;

        .notification-item-detail {
          font-size: 1em;
          margin-left: 15px;
          color: #666666ff;
        }

        .notification-item-icon-wrapper {
          background-color: white;
          border-radius: 32px;
          position: absolute;
          right: 0;
          z-index: 3;
          margin-right: 32px;
          visibility: hidden;
          &:hover {
            .ant-list-item {
              background-color: white;
            }
          }
        }
      }

      .nofication-item-wrapper:hover {
        cursor: pointer;
        background-color: lightgrey;
      }

      .notification-header {
        font-size: 2em;
        font-weight: 700;
        color: #666666ff;
      }
    }
  }
}

.popover-items {
  padding: 2px;
  display: flex;
  cursor: pointer;
  &:hover {
    background-color: lightgrey;
  }
  .popover-icons {
    margin-top: 5px;
  }
  .popover-text {
    margin-left: 10px;
  }
}
