.list {
  display: flex;
  gap: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #ebebeb;
  line-height: normal;

  .label {
    width: 100%;
  }

  .num {
    padding-right: 24px;
  }

  &:last-of-type {
    border: 0;
  }
}
