.btn {
  width: 100%;
  min-height: 3rem;
  padding: 0.375rem 0.625rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  border: 0;
  border-radius: 0.375rem;
  font-size: 1.25rem;
  font-weight: 700;
  background: linear-gradient(221deg, #084a84 9.86%, #145cb0 76.95%);
  color: white;
  white-space: nowrap;
  line-height: normal;

  @media (width >= 1280px) {
    width: min-content;
  }
}
