.user-summary {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background-color: white;
  font-size: 1rem;

  &-title {
    font-size: clamp(1rem, 4vw, 1.25rem);
    font-weight: 700;
    line-height: 1.5rem;
    line-height: normal;
  }

  &-table {
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }

  .new-users {
    min-width: 12rem;
    min-height: 17rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;
    padding: 1rem;
    border-radius: 1.5rem;
    color: white;

    &.technicians {
      background: linear-gradient(180deg, #084a84 0%, #145cb0 100%);
    }

    &.members {
      background: linear-gradient(180deg, #fd673e 0%, #fd9426 100%);
    }

    &-title {
      max-width: 8rem;
      font-weight: 600;
      text-align: center;
      line-height: 20px;
    }

    &-count {
      font-size: 1.75rem;
      line-height: 2rem;
      font-weight: 700;
    }

    &-percentage {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.25rem;
      padding: 2px 0.5rem;
      border-radius: 0.75rem;
      background-color: white;
      color: black;
      font-size: 0.75rem;
    }

    &-grid {
      width: 100%;
      max-width: 320px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
      justify-items: center;
      margin-top: 0.5rem;

      &-item {
        font-weight: 600;
        line-height: 20px;
      }
    }
  }
}

@media (width >= 1024px) {
  .user-summary {
    flex-direction: row;
    align-items: flex-end;
    padding: 1rem;
    gap: 2rem;

    &-table {
      padding: 0;
      max-width: 46rem;
    }

    .new-users {
      order: 999; // moved to last
      max-width: 192px;
    }
  }
}
