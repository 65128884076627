.modal-body .ant-radio-group {
  display: flex;
  flex-direction: column;
  font-size: 24px;
}

.modal-body .ant-modal-content,
.modal-body .ant-modal-content .ant-modal-header {
  border-radius: 15px;
  background-color: #f9f9f9;
}

.modal-body .ant-modal-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
}

.request-modal .ant-modal-body {
  padding: 0;
  margin: 0;
}

.request-modal {
  border-radius: 10px;
  overflow: hidden;
}

.ant-picker,
.ant-select {
  width: 100%;
}

.ant-picker-input > input:placeholder-shown {
  color: #c9c9c9;
  font-style: italic;
}

.ant-input:placeholder-shown {
  color: #c9c9c9;
  font-style: italic;
}

.fake-field {
  background: white;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 16px;
}

.upload-container {
  background: white;
  border-radius: 6px;
  border: 1px solid #ddd;
  margin-bottom: 24px;
  padding: 16px;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
