.dispatcher-builder {

  background: white;
  border-radius: 16px;
  margin: 1vh;
  height: calc(100vh - 20vh);

  .dispatcher-list-container {

    display: inline-block;
    border-right: 1px solid #eee;
    height: 100%;
    vertical-align: top;
    width: 320px;

    .header {

      align-items: center;
      display: grid;
      grid-template-columns: auto 14vh;
      padding: 2vh;

      h3 {
        font-weight: bold;
        margin: 0;
      }

      div {
        cursor: pointer;
        color: #1e5c8bff;
        text-align: right;
      }
    }

    .dispatcher-list {

      height: calc(100% - 48px);
      overflow: auto;
    }
  }

  .dispatcher-detail {

    background: white;
    display: inline-block;
    height: 100%;
    padding: 2vh;
    vertical-align: top;
    width: calc(100% - 320px);

    .header {

      align-items: center;
      display: grid;
      grid-template-columns: auto 16vh;
      column-gap: 1vh;
      margin-bottom: 1vh;
      margin-right: 1vh;
      padding: 1vh 0;

      h3 {
        font-weight: bold;
        color: #1e5c8bff;
        margin: 0;
      }

      .input-header {
        border: 1px solid white;
        font-size: 11pt;
        font-weight: bold;
        color: #1e5c8bff;

        &:hover {
          border: 1px solid lightgray;
        }
      }
    }

    .dispatcher-detail-container {

      height: calc(100% - 6vh);
      overflow: auto;

      .controls {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 2vh;
      }

      .details {
        align-items: left;
        display: flex;
        flex-direction: column;
        justify-content: left;
        padding: 2vh;
        max-width: 640px;
        margin: auto;
      }
    }
  }
}

.dispatcher-button-front-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.dispatcher-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}