.link {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: #293944;

  .label {
    line-height: 1.25rem;
  }
}
