.outstanding {
  grid-area: outstanding;
}

.service-request {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #ebebeb;

  &-col {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &-name,
  &-id,
  &-type {
    color: #293944;
    font-size: 0.875rem;
    line-height: 1.125rem;
  }

  &-name,
  &-days {
    font-weight: 600;
    line-height: 1.25rem;
  }

  &-type {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-days {
    color: #f9665f;
    line-height: normal;
    white-space: nowrap;
  }
}
