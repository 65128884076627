@import "../default";

.toolbar {
  display: flex;
  flex-wrap: wrap;
  .toolbar-ls {
    display: flex;
    flex: 2;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 0px 8px;
    border: 1px solid $border;
  }

  .toolbar-rs {
    display: flex;
    flex: 1;
    align-items: center;
  }
}

.toolbar-ls-filter {
  display: flex;
  flex-direction: row;
  font-size: 1em;
  align-items: center;
  white-space: nowrap;
}

.toolbar-ls-preset-filter {
  display: flex;
  align-items: center;
  .toolbar-ls-preset-filter-arrow-l,
  .toolbar-ls-preset-filter-arrow-r {
    cursor: pointer;
  }
}

.toolbar-ls-preset-filter-content {
  overflow-x: hidden;
  display: flex;
  width: 100%;
  margin-left: 16px;
  margin-right: 16px;
  .ant-radio-button-wrapper {
    display: flex;
    justify-content: center !important;
    align-items: center;
    height: unset !important;
    border: none !important;
    line-height: unset !important;
    background-color: transparent !important;
    white-space: pre !important;
  }
  .ant-radio-button-wrapper:hover {
    color: $fontHeaderColor;
  }
  .ant-radio-button-wrapper:focus-within {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    font-weight: bold !important;
    color: $fontHeaderColor !important;
  }
  .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
    border: none !important;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
    color: $fontHeaderColor !important;
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    width: 0px !important;
  }
}

.toolbar-date-filter {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  border: none;
  padding: 6px 8px;
  margin-left: 16px;

  .ant-picker-input {
    width: 140px;
    input {
      width: 100px;
      font-size: 1em;
    }
  }
}

.ant-picker:hover,
.ant-picker-focused {
  border: none;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.toolbar-rs-filter {
  display: flex;
  flex-direction: row;
  margin-left: 8px;
  .ant-radio-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: unset !important;
    border: none !important;
    line-height: unset !important;
    background-color: transparent !important;
    white-space: nowrap;
  }
  .ant-radio-button-wrapper:hover {
    color: $fontHeaderColor;
  }
  .ant-radio-button-wrapper:focus-within {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    font-weight: bold !important;
    color: $fontHeaderColor !important;
  }
  .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
    border: none !important;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
    color: $fontHeaderColor !important;
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    width: 0px !important;
  }
}

.toolbar-rs-btns {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  margin-right: 8px;
  button {
    span {
      font-size: 12px;
    }
  }
}

.legend-container {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  .legend {
    display: flex;
    font-weight: 500;
    width: 250px;
    .legend-icon {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      align-items: center;
      margin-right: 8px;
    }
  }
  .legend-content {
    visibility: visible;
    margin-top: 24px;
    position: absolute;
    z-index: 4;
    padding-left: 16px;
    padding-bottom: 16px;
    width: 250px;
    background-color: $white;
    height: auto;
    -moz-transition: height 0.2s;
    -ms-transition: height 0.2s;
    -o-transition: height 0.2s;
    -webkit-transition: height 0.2s;
    transition: height 0.2s;
    justify-content: space-between;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  .legend-content-hidden {
    margin-top: 30px;
    position: absolute;
    visibility: hidden;
  }
}

.legend-content {
  .legend-sr {
    display: flex;
    .legend-color-status {
      display: flex;
      height: 12px;
      width: 12px;
      line-height: 60px;
      -moz-border-radius: 30px;
      border-radius: 30px;
      background-color: black;
      margin: 6px;
      color: white;
      text-align: center;
      font-size: 2em;
    }
  }
}
