.payments {
  grid-area: payments;
}

.container {
  height: 100%;
  padding: 0 6em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  img {
    width: 8rem;
    height: 8rem;
  }

  .payments {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .row {
      display: flex;
      gap: 0.5rem;
    }

    .label {
      font-weight: 600;
    }

    .bank,
    .e-wallet {
      font-weight: 700;
    }

    .bank {
      color: #145cb0;
    }

    .e-wallet {
      color: #fd9426;
    }
  }
}
