.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 1rem;
  padding-top: 0px;
  border-bottom: 1px solid #ebebeb;

  .circle-progress-container {
    position: relative;

    .progress {
      width: 8rem;
      height: 8rem;
    }

    .center {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      font-weight: 700;

      .percent {
        font-size: 1.75rem;
        line-height: 2rem;
      }

      .complete {
        font-size: 0.75rem;
        line-height: 1rem;
        color: #0c71c3;
      }
    }
  }

  .grid-results {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
    color: #293944;

    .responded {
      height: 2rem;
      font-weight: 700;
      line-height: 1.25rem;
      text-align: right;
      color: #145cb0;
    }

    .waiting {
      height: 2rem;
      font-weight: 700;
      line-height: 1.25rem;
      text-align: right;
      color: #a5a5a5;
    }

    .total {
      height: 2rem;
      font-weight: 700;
      line-height: 1.25rem;
      text-align: right;
    }

    .label {
      line-height: 1.25rem;
      font-weight: 600;
    }
  }
}
