.status {
  width: min-content;
  height: min-content;
  padding: 0 0.375rem;
  border-radius: 6px;
  color: white;
  font-size: 0.75rem;
  line-height: 1.25rem;
  white-space: nowrap;

  &.active,
  &.logged-in {
    background-color: #1ace6d;
  }

  &.idle {
    background-color: #fd783f;
  }

  &.offline,
  &.logged-out {
    background-color: #a5a5a5;
  }
}
