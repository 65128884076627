.side-menu {
  display: flex;
  flex-direction: column;

  .fsa-avatar-wrapper {
    flex-direction: column;
    display: flex;
    padding: 16px;
    justify-content: center;
    align-items: center;
  }

  .version {
    margin-top: 8px;
    color: white;
    font-size: .9em;
  }

  .divider-wrapper {
    padding-left: 8px;
    padding-right: 8px;
    .ant-divider {
      border-color: #d3d3d3;
      border-width: 0.5;
      width: auto;
    }
  }

  .side-menu-menu-wrapper {
    padding-left: 4px;
    padding-right: 4px;

    .ant-menu {
      background: #1e5c8bff;

      .ant-menu-item {
        font-size: 0.8em;
        font-weight: 600;
        color: #ffffffff;
        margin-bottom: 0;
      }
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background: #477187ff;
    }

    .ant-menu-vertical .ant-menu-item::after,
    .ant-menu-vertical-left .ant-menu-item::after,
    .ant-menu-vertical-right .ant-menu-item::after,
    .ant-menu-inline .ant-menu-item::after {
      border-right: none;
    }

    .ant-menu-inline,
    .ant-menu-vertical,
    .ant-menu-vertical-left {
      border: none;
    }
  }
}

@media only screen and (max-width: 768px) {
}

@media only screen and (min-width: 992px) {
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1600px) {
}
