@import "../../assets/stylesheets/default";

.submit-button {
   border-radius: 8px !important;
   background-color: $backgroundDarkblue !important;
}

.submit-button:hover,
.submit-button:focus,
.submit-button:active {
   background-color: $backgroundHighlight !important;
}

.submit-button-text {
   color: $fontColorLight !important;
}
