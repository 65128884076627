.form-password-container {
    background-color: #ffffff;
    border: 1px groove #3a78b3;
    border-radius: 10px;
    width: 400px;
    height: 380px;
    padding: 20px;
    margin: 0;

    .form-password-header {
        font-size: 30px;
        font-weight: bold;
        text-align: center;
    }

    .form-password-subContainer {
        margin-top: 40px;
    }
}