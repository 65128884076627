/*-- width height utilities --*/
.full-width {
  width: 100%;
}
.half-width {
  width: 50%;
}
.full-height {
  height: 100%;
}
.half-height {
  height: 100%;
}
.full-vwidth {
  width: 100vw;
}
.half-vwidth {
  width: 50vw;
}
.full-vheight {
  height: 100vh;
}
.half-vheight {
  height: 50vh;
}

/*-- float utilities --*/
.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}
.float-none {
  float: none !important;
}

/*-- flex utilities --*/
.flex {
  display: flex;
  align-items: center;
}

.flex-center {
  justify-content: center;
}

.flex-start {
  justify-content: flex-start;
}

.flex-row {
  flex-direction: row;
}

.flex-row {
  flex-direction: column;
}

/*-- display utilities --*/

.disp-flex {
  display: flex;
}

.disp-block {
  display: block;
}

.disp-none {
  display: none;
}

/*-- position utilities --*/
.pos-absolute {
  position: absolute;
}
.pos-relative {
  position: relative;
}
.pos-fixed {
  position: fixed;
}

/*-- font size utilities --*/
.fs-normal {
  font-size: 1em !important;
}
.fs-small {
  font-size: 0.8em !important;
}
.fs-xsmall {
  font-size: 0.7em !important;
}
.fs-1 {
  font-size: 1.1em !important;
}
.fs-2 {
  font-size: 1.2em !important;
}
.fs-3 {
  font-size: 1.3em !important;
}
.fs-4 {
  font-size: 1.4em !important;
}
.fs-5 {
  font-size: 1.5em !important;
}
.fs-6 {
  font-size: 1.6em !important;
}
.fs-7 {
  font-size: 1.7em !important;
}
.fs-8 {
  font-size: 1.8em !important;
}
.fs-9 {
  font-size: 1.9em !important;
}
.fs-10 {
  font-size: 2em !important;
}

/*-- text utilities --*/
.text-italic {
  font-style: italic;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-lowercase {
  text-transform: lowercase;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-white {
  color: #fff !important;
}
.text-black {
  color: #000 !important;
}
.text-bold3 {
  font-weight: 900;
}
.text-bold2 {
  font-weight: 800;
}
.text-bold1 {
  font-weight: 700;
}

/*-- border-radius utilities --*/
.br-normal {
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}
.br-0 {
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
}
.br-1 {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
}
.br-2 {
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
}
.br-3 {
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
}
.br-4 {
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
}
.br-5 {
  border-radius: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
}
.br-6 {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
}
.br-circle {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}

.br-t-normal {
  border-top-left-radius: 3px;
  -webkit-border-top-left-radius: 3px;
  -moz-border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  -webkit-border-top-right-radius: 3px;
  -moz-border-top-right-radius: 3px;
}
.br-t-0 {
  border-top-left-radius: 0;
  -webkit-border-top-left-radius: 0;
  -moz-border-top-left-radius: 0;
  border-top-right-radius: 0;
  -webkit-border-top-right-radius: 0;
  -moz-border-top-right-radius: 0;
}
.br-t-1 {
  border-top-left-radius: 5px;
  -webkit-border-top-left-radius: 5px;
  -moz-border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-top-right-radius: 5px;
}
.br-t-2 {
  border-top-left-radius: 6px;
  -webkit-border-top-left-radius: 6px;
  -moz-border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  -webkit-border-top-right-radius: 6px;
  -moz-border-top-right-radius: 6px;
}
.br-t-3 {
  border-top-left-radius: 7px;
  -webkit-border-top-left-radius: 7px;
  -moz-border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  -webkit-border-top-right-radius: 7px;
  -moz-border-top-right-radius: 7px;
}
.br-t-4 {
  border-top-left-radius: 8px;
  -webkit-border-top-left-radius: 8px;
  -moz-border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  -webkit-border-top-right-radius: 8px;
  -moz-border-top-right-radius: 8px;
}
.br-t-5 {
  border-top-left-radius: 9px;
  -webkit-border-top-left-radius: 9px;
  -moz-border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  -webkit-border-top-right-radius: 9px;
  -moz-border-top-right-radius: 9px;
}
.br-t-6 {
  border-top-left-radius: 10px;
  -webkit-border-top-left-radius: 10px;
  -moz-border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  -webkit-border-top-right-radius: 10px;
  -moz-border-top-right-radius: 10px;
}

.br-b-normal {
  border-bottom-left-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  -moz-border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
  -moz-border-bottom-right-radius: 3px;
}
.br-b-0 {
  border-bottom-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  -moz-border-bottom-right-radius: 0;
}
.br-b-1 {
  border-bottom-left-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -moz-border-bottom-right-radius: 5px;
}
.br-b-2 {
  border-bottom-left-radius: 6px;
  -webkit-border-bottom-left-radius: 6px;
  -moz-border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  -webkit-border-bottom-right-radius: 6px;
  -moz-border-bottom-right-radius: 6px;
}
.br-b-3 {
  border-bottom-left-radius: 7px;
  -webkit-border-bottom-left-radius: 7px;
  -moz-border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  -webkit-border-bottom-right-radius: 7px;
  -moz-border-bottom-right-radius: 7px;
}
.br-b-4 {
  border-bottom-left-radius: 8px;
  -webkit-border-bottom-left-radius: 8px;
  -moz-border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  -webkit-border-bottom-right-radius: 8px;
  -moz-border-bottom-right-radius: 8px;
}
.br-b-5 {
  border-bottom-left-radius: 9px;
  -webkit-border-bottom-left-radius: 9px;
  -moz-border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  -webkit-border-bottom-right-radius: 9px;
  -moz-border-bottom-right-radius: 9px;
}
.br-b-6 {
  border-bottom-left-radius: 10px;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  -webkit-border-bottom-right-radius: 10px;
  -moz-border-bottom-right-radius: 10px;
}

/*-- spacing utilities --*/
.m-0 {
  margin: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.mt-1 {
  margin-top: 0.25rem !important;
}
.mr-1 {
  margin-right: 0.25rem !important;
}
.mb-1 {
  margin-bottom: 0.25rem !important;
}
.ml-1 {
  margin-left: 0.25rem !important;
}
.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}
.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.mt-2 {
  margin-top: 0.5rem !important;
}
.mr-2 {
  margin-right: 0.5rem !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}
.ml-2 {
  margin-left: 0.5rem !important;
}
.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}
.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.mt-3 {
  margin-top: 1rem !important;
}
.mr-3 {
  margin-right: 1rem !important;
}
.mb-3 {
  margin-bottom: 1rem !important;
}
.ml-3 {
  margin-left: 1rem !important;
}
.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}
.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.mt-4 {
  margin-top: 1.5rem !important;
}
.mr-4 {
  margin-right: 1.5rem !important;
}
.mb-4 {
  margin-bottom: 1.5rem !important;
}
.ml-4 {
  margin-left: 1.5rem !important;
}
.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}
.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.mt-5 {
  margin-top: 3rem !important;
}
.mr-5 {
  margin-right: 3rem !important;
}
.mb-5 {
  margin-bottom: 3rem !important;
}
.ml-5 {
  margin-left: 3rem !important;
}
.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}
.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}
.m-auto {
  margin: auto !important;
}
.mt-auto {
  margin-top: auto !important;
}
.mr-auto {
  margin-right: auto !important;
}
.mb-auto {
  margin-bottom: auto !important;
}
.ml-auto {
  margin-left: auto !important;
}
.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.pt-1 {
  padding-top: 0.25rem !important;
}
.pr-1 {
  padding-right: 0.25rem !important;
}
.pb-1 {
  padding-bottom: 0.25rem !important;
}
.pl-1 {
  padding-left: 0.25rem !important;
}
.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}
.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.pt-2 {
  padding-top: 0.5rem !important;
}
.pr-2 {
  padding-right: 0.5rem !important;
}
.pb-2 {
  padding-bottom: 0.5rem !important;
}
.pl-2 {
  padding-left: 0.5rem !important;
}
.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}
.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.pt-3 {
  padding-top: 1rem !important;
}
.pr-3 {
  padding-right: 1rem !important;
}
.pb-3 {
  padding-bottom: 1rem !important;
}
.pl-3 {
  padding-left: 1rem !important;
}
.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}
.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.pt-4 {
  padding-top: 1.5rem !important;
}
.pr-4 {
  padding-right: 1.5rem !important;
}
.pb-4 {
  padding-bottom: 1.5rem !important;
}
.pl-4 {
  padding-left: 1.5rem !important;
}
.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.pt-5 {
  padding-top: 3rem !important;
}
.pr-5 {
  padding-right: 3rem !important;
}
.pb-5 {
  padding-bottom: 3rem !important;
}
.pl-5 {
  padding-left: 3rem !important;
}
.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}
.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

/*-- Others --*/
.pointer {
  cursor: pointer;
}
.arrow-left {
  width: 0;
  height: 0;
  border-top: 35px solid transparent;
  border-bottom: 35px solid transparent;
  // border-right: 35px solid red;
  border-right: 35px solid #ccccff;
  position: absolute;
  margin-left: -5px;
}
.relative {
  position: relative;
  padding-left: 30px;
  margin-top: -20px;
}

//disabled

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
