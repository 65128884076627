.service-location-container {
    background: white;
    border-radius: 16px;
    margin: 1vh;
    height: calc(100vh - 20vh);

    .header {
        display: flex;
        flex-direction: row;
        padding: 3vh 2vh;
        align-items: center;
        width: 100%;
        h3 {
            font-weight: bold;
            margin: 0;
            width: 50%;
        }

        .actions {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            width: 50%;
        }
    }


    .content {
        display: flex;
        flex-direction: row;
        height: 100%;
        width: 100%;

        .filter-container {
            padding: 2vh;
            display: inline-block;
            // border-right: 1px solid #eee;
            height: 100%;
            vertical-align: top;
            width: 25vw;

            .sub-header {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding: 4px;
            }
        }

        .form-container {
            display: flex;
            flex-direction: column;
            padding: 1vw 2vw;
            width: 75vw;
            h3 {
                color: #1e5c8bff;
                font-weight: bold;
            }

            .custom-form {
                margin-top: 12px;
                width: 12vw;
            }
        }

        .custom-input-toggler {
            display: flex;
            flex-direction: row;
            align-items: center;


            .custom-input {
                margin-left: 6px;
                margin-right: 6px;
            }

            .toggler {
                cursor: pointer;
                background-color: #d9d9d9;
                padding: 8px;
            }
        }

    }

    .actions {
        .action {
            background-color: purple;
            border-color: purple;
            margin-right: 8px;
        }
    }

    .filter-container {
        .list-container {
            height: calc(100% - 160px);
            overflow-x: hidden;
            font-size: 16px;
            // margin-left: 24px;
            line-height: 18px;

            .list-item {
                padding: 16px;
                margin-bottom: 0px;
            }

            .list-item-active {
                padding: 16px;
                margin-bottom: 0px;

                label {
                    color: #1e5c8bff;
                    font-weight: bold;
                }

                background-color: #eeeeee;
                .ant-tag {
                    text-align: center;
                }
            }

            .list-item:hover {

                label {
                    color: #1e5c8bff;
                    font-weight: bold;
                }

                background-color: #eeeeee;
            }
        }
    }

    .list-item {
        .ant-tag {
            text-align: center;
        }
    }


    .sub-header {
        .sorter {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;

            .sort-icons {
                margin-top: 2px;
                margin-left: 4px;
                align-self: flex-start;
            }
        }
    }
}

.custom-modal {

    .custom-input-toggler {
        width: 50%;
        display: flex;
        flex-direction: row;
        align-items: center;
        .custom-input {
            margin-left: 6px;
            margin-right: 6px;
        }
    
        .toggler {
            cursor: pointer;
            background-color: #d9d9d9;
            padding: 8px;
        }
    }
    
}



.upload-title {
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    word-wrap: break-word;
}

.uploads {
    margin-left: 16px;
    display: flex;
    flex-direction: row;
}

.button-container {
    width: 100%;

    .button-container-left {
        float: left;
    }

    .button-container-right {
        float: right;
    }
}

.button-container-center {
    margin-top: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-container-right {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.modalStyle .ant-modal-header {
    background-color: #7badd6;
}